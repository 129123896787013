import { ReactNode } from "react";
export type LinkProps = {
  destination: string;
  children: ReactNode;
  className?: string;
  relativePath?: boolean;
  target?: "_self" | "_blank";
  rel?: "nofollow" | "";
  type?: string;
};

export default function Link({
  destination,
  className,
  children,
  relativePath = true,
  target = "_self",
  rel = "",
  type,
}: LinkProps) {
  return (
    <a
      href={relativePath ? `/${destination}` : destination}
      className={className ?? `text-sm text-grey-400`}
      target={target}
      rel={rel}
      type={type}
    >
      {children}
    </a>
  );
}
