import { ArticlePage } from "@pages/Article/Article.tsx";
import { AuthorPage } from "@pages/Author/AuthorPage";
import { FoodMapPage } from "@pages/FoodMap/FoodMapPage";
import { HomePage } from "@pages/Home/Home.tsx";
import { KeywordPage } from "@pages/Keyword/Keyword";
import { NewsletterPage } from "@pages/Newsletter/Newsletter";
import { SearchResultPage } from "@pages/Search/SearchResult";
import { SectionPage } from "@pages/Section/Section";
import HubungiKami from "@pages/StaticPages/HubungiKami";
import KenaliKami from "@pages/StaticPages/KenaliKami";
import { UGCListingPage } from "@pages/UGC/UGCListingPage/UGCListingPage";
import { AuthCallback } from "@sphtech/web2-core/auth";
import { fetchContext } from "@sphtech/web2-core/ssr";
import { WebCoreSecretsManager } from "@sphtech/web2-secrets";
import { UGCLandingPage } from "@src/app/pages/UGC/UGCLandingPage/UGCLandingPage";
import { OSResponse, PartialCueArticle } from "@typings/Opensearch";
import { RouteObject } from "react-router-dom";

import {
  getClientId,
  getIssuer,
} from "../../.web2/web2-helpers/auth/MySphConfig";
import App from "./App";
import { ARTICLE_INDEX } from "./helper/fetchCaas";
import { getErrorMessage, reportError } from "./helper/getErrorMessage";
import PodcastPage from "./pages/Podcast/Podcast";
import { UgcArticlePage } from "./pages/UGC/UGCArticlePage/UGCArticlePage";
import VideoPage from "./pages/Video/Video";

enum CaasSource {
  Cue = "cue",
  Awedio = "awedio",
  Brightcove = "brightcove",
}
enum CaasSchema {
  Cue = "cue-article",
}
enum CaasEvent {
  Create = "create",
  Delete = "delete",
}
enum CueEventState {
  Published = "published",
  Unpublished = "unpublished",
}
type CaasConcisePayload = {
  _meta: {
    version?: string;
    messageId: string;
    source: `${CaasSource}`;
    schema?: `${CaasSchema}`;
    timeReceived: string;
    requestId?: string;
    eventType: `${CaasEvent}`;
  };
  payload: {
    id: string;
    type: string;
    state: `${CueEventState}`;
    publication: string;
    urlPath: string;
    urlPathHistory: Array<string>;
  };
};

type OriginResponsePayload = {
  urlPath: string;
};

/**
 * This function is a type guard to check whether the content data matches that of the CaaS's full payload
 * implementation.
 * @param { PartialCueArticle | CaasConcisePayload } content
 * @returns { boolean } A boolean flag to indicate whether the incoming payload is of CaaS's concise payload type.
 */
const isContentConcisePayload = function iksContentConcisePayload(
  content: PartialCueArticle | CaasConcisePayload | OriginResponsePayload,
): content is CaasConcisePayload {
  const isEmptyObj =
    typeof content === "object" && Object.keys(content).length === 0;
  const hasMetaEventType =
    "_meta" in content &&
    "eventType" in content._meta &&
    Object.values(CaasEvent).includes(
      content._meta.eventType as unknown as CaasEvent,
    );
  const hasCueState =
    "payload" in content &&
    "state" in content.payload &&
    Object.values(CueEventState).includes(
      content.payload.state as unknown as CueEventState,
    );

  return !isEmptyObj && hasMetaEventType && hasCueState;
};

const isContentFromOriginResponse = function isContentFromOriginResponse(
  content: PartialCueArticle | CaasConcisePayload | OriginResponsePayload,
): content is OriginResponsePayload {
  const isEmptyObj =
    typeof content === "object" && Object.keys(content).length === 0;

  const keys = Object.keys(content);
  const hasOnlyUrlPath = keys.length === 1 && keys[0] === "urlPath";

  return !isEmptyObj && hasOnlyUrlPath;
};

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: fetchContext,
      },
      /**
       * Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
       */
      {
        path: "/oauth/callback",
        element: <AuthCallback clientId={getClientId()} issuer={getIssuer()} />,
      },
      {
        path: "/ringkasan-berita-harian",
        element: <NewsletterPage />,
        loader: fetchContext,
      },
      {
        path: "/mysph/login.php/",
        element: <></>,
        loader: fetchContext,
      },
      {
        path: "/foodmap",
        element: <FoodMapPage />,
        loader: fetchContext,
      },
      {
        path: "/hubungi-kami",
        element: <HubungiKami />,
        loader: fetchContext,
      },
      {
        path: "/kenali-kami",
        element: <KenaliKami />,
        loader: fetchContext,
      },
      {
        path: "/videos",
        element: <VideoPage />,
        loader: fetchContext,
      },
      {
        path: "/videos/:level1",
        element: <VideoPage />,
        loader: fetchContext,
      },
      {
        path: "/podcasts",
        element: <PodcastPage />,
        loader: fetchContext,
      },
      {
        path: "/podcasts/:level1",
        element: <PodcastPage />,
        loader: fetchContext,
      },
      {
        path: "/mata-mata",
        element: <UGCLandingPage />,
        loader: fetchContext,
      },
      {
        path: "/mata-mata/:level1",
        element: <UGCListingPage />,
        loader: fetchContext,
      },
      {
        path: "/mata-mata/:refid/:level1",
        element: <UgcArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/tags/:level1",
        element: <KeywordPage />,
        loader: fetchContext,
      },
      {
        path: "/author/:level1",
        element: <AuthorPage />,
        loader: fetchContext,
      },
      {
        path: "/gaya-hidup/mahligai",
        element: <SectionPage />,
        loader: fetchContext,
      },
      {
        path: "/gaya-hidup/wanita",
        element: <SectionPage />,
        loader: fetchContext,
      },
      {
        path: "/gaya-hidup/hiburan",
        element: <SectionPage />,
        loader: fetchContext,
      },
      {
        path: "/search/site/:searchValue",
        element: <SearchResultPage />,
        loader: fetchContext,
      },
      {
        path: "/:section/:id",
        element: <ArticlePage />,
        loader: fetchContext,
      },
      {
        path: "/:level1",
        element: <SectionPage />,
        loader: fetchContext,
      },
      {
        path: "*",
        element: (
          <>
            <h1>404</h1>
            <br />
            <h2>The page you are looking for is not found!</h2>
          </>
        ),
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);

/**
 * This function helps to generate the dependent paths for SSG whenever there is an incoming SQS payload.
 * During SSG, the Web2 render Lambda function will invoke this function to get a list of additional routes to
 * render.
 * @param {PartialCueArticle} content Content parsed from incoming SQS payload.
 * @returns {Array<string>} A list of dependent paths for SSG.
 */
export const getDependentPaths = async function getDependentPaths(
  content: PartialCueArticle | CaasConcisePayload,
): Promise<Array<string>> {
  // By default, the homepage is always re-generated whenever an article has been published, updated or unpublished.
  const dependentPaths: Array<string> = [""];
  let data: PartialCueArticle | undefined;

  if (
    isContentConcisePayload(content) ||
    isContentFromOriginResponse(content)
  ) {
    try {
      console.log("Looking up content data with data fetcher");
      const dataUri: string = isContentConcisePayload(content)
        ? content.payload.urlPath
        : content.urlPath;
      const caasApiUrl = import.meta.env.VITE_CAAS_API_URL as string;
      const caasApiKey = (
        await WebCoreSecretsManager.getWeb2Secret("CAAS_API_KEY")
      ).value;

      if (!caasApiKey || !caasApiUrl) {
        throw new Error(
          "Missing VITE_CAAS_API_URL env variable or CAAS_API_KEY secret.",
        );
      }

      const fetchResponse = await fetch(caasApiUrl, {
        method: "POST",
        headers: {
          "x-api-key": caasApiKey,
        },
        body: JSON.stringify({
          index: ARTICLE_INDEX,
          search: {
            size: 1,
            query: {
              term: {
                "data.context.urlPath": dataUri,
              },
            },
          },
        }),
      });

      data = ((await fetchResponse.json()) as OSResponse<PartialCueArticle>)
        .payload.hits.hits[0]?._source;
    } catch (error) {
      reportError({ message: getErrorMessage(error) });
    }
  } else {
    console.log("Take in data as it is a full payload");
    data = content;
  }

  if (data?.data.context) {
    console.log(`Data value:: ${JSON.stringify(data.data.context)}`);
    const { sections, typeName } = data.data.context;
    if (sections && sections.length > 0) {
      sections.forEach(({ uniqueName }) => {
        // This logic is to handle the mata-mata section as some of the UGC articles are not under the mata-mata section.
        if (typeName === "Campaign") {
          if (uniqueName === "mata-mata") {
            dependentPaths.push(`/${uniqueName}`);
          } else {
            dependentPaths.push(`/mata-mata}`);
          }
        } else {
          dependentPaths.push(`/${uniqueName}`);
        }
      });
    } else {
      // Handle the case where sections is undefined or an empty array
      console.error("No sections found in content.data.context.sections");
    }
  }
  return dependentPaths;
};
