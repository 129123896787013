import FoodMapDesktop from "@components/FoodMap/FoodMapElements/FoodMapDesktop";
import FoodMapGoogleMaps from "@components/FoodMap/FoodMapGoogleMaps";
import FoodMapLocationPermission from "@components/FoodMap/FoodMapLocationPermission";
import { isLocationInSingapore, roundToFourDecimals } from "@helper/utils";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import { useVirtualPVAnalyticsCall } from "@hooks/useAnalyticsCall/useVirtualPVAnalyticsCall";
import useRemoveOverlay from "@hooks/useRemoveOverlay";
import useUserLocation from "@hooks/useUserLocation/useUserLocation";
import useWatchPosition from "@hooks/useUserLocation/useWatchPosition";
import { Point, PositionError } from "@typings/foodMapTypes";
import { useEffect, useState } from "react";

import FoodMapMetaData from "./FoodMapMetaData";
import styles from "./FoodMapPage.module.css";

export function FoodMapPage() {
  const [userLocation, setUserLocation] = useState<Point | undefined>();
  const [watchLocation, setWatchLocation] = useState<Point>();
  const [geoError, setGeoError] = useState<
    PositionError | GeolocationPositionError
  >();
  const [initLocation, setInitLocation] = useState<boolean>(false);
  useRemoveOverlay();
  const { location, error } = useUserLocation();
  const { userPosition, watchError } = useWatchPosition();
  const bhCustomEventAnalyticsCall = useBHCustomEventAnalyticsCall();
  const virtualPVAnalyticsCall = useVirtualPVAnalyticsCall();

  useEffect(() => {
    if (!initLocation) {
      if (location) {
        if (!isLocationInSingapore(location)) {
          setWatchLocation(location);
          setGeoError({
            code: 404,
            message: "Location out of Singapore",
          });
        } else {
          setUserLocation(location);
          setWatchLocation(location);
          setInitLocation(true);
          bhCustomEventAnalyticsCall("foodmap", "initialize", undefined, false);
          virtualPVAnalyticsCall(
            "/foodmap",
            "Food Map List",
            `${location.lat}, ${location.lng}`,
          );
        }
      } else {
        if (error) {
          setGeoError(error);
        }
      }
    }
  }, [
    initLocation,
    location,
    error,
    bhCustomEventAnalyticsCall,
    virtualPVAnalyticsCall,
  ]);

  useEffect(() => {
    const roundedUserLat = userPosition
      ? roundToFourDecimals(userPosition.lat)
      : null;
    const roundedUserLng = userPosition
      ? roundToFourDecimals(userPosition.lng)
      : null;
    const roundedWatchLat = watchLocation
      ? roundToFourDecimals(watchLocation.lat)
      : null;
    const roundedWatchLng = watchLocation
      ? roundToFourDecimals(watchLocation.lng)
      : null;

    if (
      userPosition &&
      watchLocation &&
      (roundedUserLat !== roundedWatchLat || roundedUserLng !== roundedWatchLng)
    ) {
      if (!isLocationInSingapore(userPosition)) {
        setWatchLocation(userPosition);
        setGeoError({
          code: 404,
          message: "Location out of Singapore",
        });
      } else {
        setUserLocation(userPosition);
        setWatchLocation(userPosition);
        virtualPVAnalyticsCall(
          "/foodmap",
          "Food Map List",
          `${userPosition.lat}, ${userPosition.lng}`,
        );
      }
    } else {
      if (watchError) {
        setGeoError(watchError);
        setUserLocation(undefined);
      }
    }
  }, [userPosition, watchLocation, watchError, virtualPVAnalyticsCall]);

  return (
    <>
      <FoodMapMetaData />
      <div
        className={`block fixed lg:static top-0 w-full h-full ${styles.foodmap}`}
      >
        <FoodMapDesktop />
        <div className={`flex flex-col lg:hidden max-w-[820px]`}>
          {userLocation !== undefined ? (
            <FoodMapGoogleMaps
              foodMapWrapper="flex"
              userLocation={userLocation}
            />
          ) : (
            geoError && <FoodMapLocationPermission error={geoError} />
          )}
        </div>
      </div>
    </>
  );
}
