export enum AdvertisementTypeEnum {
  LB1 = "lb1",
  LB2 = "lb2",
  IMU1 = "imu1",
  IMU2 = "imu2",
  IMU3 = "imu3",
  MIDARTICLESPECIAL = "midarticlespecial",
  PRESTITIAL = "prestitial",
  CATFISH = "catfish",
}

export type AdUnitProps = {
  rootClassName?: string;
  type: AdvertisementTypeEnum;
  slot: string;
  id?: string;
  pubAdTargeting: AdTargetingType[] | AdTargetingType<PageAdTargetValue>[];
};

export type PageAdTargetValue =
  | PageAdTargetingTypeEnum.HOME
  | PageAdTargetingTypeEnum.LISTING
  | PageAdTargetingTypeEnum.TAG
  | PageAdTargetingTypeEnum.ARTICLE;

export type AdTargetingType<T = string | string[]> = {
  key: string;
  value: T;
};

export type CompanionAdUnitProps = AdUnitProps & {
  adSlotSize: googletag.GeneralSize | undefined;
  pageNumber: string;
};

export enum PageAdTargetingTypeEnum {
  HOME = "home",
  LISTING = "listing",
  TAG = "tag",
  ARTICLE = "article",
}
