import {
  lb1AdSlotSizesInfinteScroll,
  pubPageAdTargeting,
} from "@elements/Advertisement/AdsConstants";
import { stripeAdsBackground } from "@elements/Advertisement/Advertisement";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetValue,
} from "@typings/Ads.d";

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @name - name of section or page
 * @index - use when lb1 is rendered more than 1
 */
export function CompanionAdsInfiniteScroll({
  sectionName,
  index,
  stripeBackground = true,
  pageAdTargetType,
}: {
  sectionName?: string;
  index: number;
  stripeBackground?: boolean;
  pageAdTargetType: PageAdTargetValue;
}) {
  let overrideLb1Slot = lb1AdSlotSizesInfinteScroll["desktop"];
  if (typeof window !== "undefined" && screen.width < 767) {
    overrideLb1Slot = lb1AdSlotSizesInfinteScroll["mobile"];
  }

  const pageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <div
      className="flex items-center justify-center w-full"
      style={stripeBackground ? stripeAdsBackground : undefined}
    >
      <CompanionAdvertisement
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(sectionName),
          id: `dfp-ad-lb1-${index}`,
          rootClassName: "m-0",
          adSlotSize: overrideLb1Slot,
          pageNumber: index.toString(),
          pubAdTargeting: [pageAdTarget],
        }}
      />
    </div>
  );
}
