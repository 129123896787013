import LabelCardIcon from "@components/Icon/LabelCardIcon";
import { getContentCat } from "@elements/GAData/GADataHelper";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import ShareSocial, {
  type SocialVariant,
} from "@elements/ShareSocial/ShareSocial";
import Heading from "@elements/Typography/Heading";
import { StandFirst } from "@elements/Typography/Paragraph";
import {
  replaceDashAndWithSpace,
  replaceSpacesWithUnderscores,
} from "@helper/stringTransform";
import BHBasePage from "@pages/BHBasePage";
import { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import ArticleCarousel from "@src/app/components/Carousel/ArticleCarousel";
import SideBlockAds from "@src/app/components/Container/SideBlockAds";
import ArticleParagraph from "@src/app/components/Elements/ArticleParagraph/ArticleParagraph";
import AttachmentCard from "@src/app/components/Elements/AttachmentCard/AttachmentCard";
import AuthorCard from "@src/app/components/Elements/Card/Author/AuthorCard";
import Date from "@src/app/components/Elements/Typography/Date";
import { RenderTags } from "@src/app/components/Elements/Typography/Tags";
import { SectionTitle } from "@src/app/components/Elements/Typography/Title";
import PodcastGallery from "@src/app/components/Gallery/PodcastGallery";
import VideoGallery from "@src/app/components/Gallery/VideoGallery";
import { isArrayWithElements } from "@src/app/helper/utils";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import { PayloadResponseType } from "./Article.server";

export function ArticlePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;
  const {
    sectionName,
    title,
    tags,
    authors,
    date,
    dateCreated,
    time,
    articleBody,
    standFirst,
    contentAccess,
    urlPath,
    imageGallery,
    relatedArticles,
    attachments,
  } = dataLoaderResponse.payload.article;

  const latestRegisteredArticle =
    dataLoaderResponse.payload.latestRegisteredArticle;

  const pageUrl = `${sectionName}/${urlPath}`;

  const level2 = sectionName
    ? replaceDashAndWithSpace(sectionName).toLowerCase()
    : "";
  const basePageProps: BHBasePageProps = {
    sectionUniqueName: sectionName,
    pageAdTargetValue: pageAdTargetValue,
    gaData: {
      title: replaceSpacesWithUnderscores(title),
      level2: level2,
      contentcat: getContentCat(contentAccess),
      section: level2,
    },
  };
  const socialIconList = ["facebook", "telegram", "plainWhatsapp", "newsPost"];

  return (
    <BHBasePage {...basePageProps}>
      <div className="flex flex-col lg:flex-row w-full gap-6">
        <div className="flex flex-col lg:px-xs">
          <div className="flex items-center gap-3 w-full">
            <SectionTitle className="mb-0" sectionName={sectionName} />
            <LabelCardIcon contentAccess={contentAccess} />
          </div>
          <Heading
            Element="h1"
            className="font-secondary leading-2xl text-black-200 text-xl lg:text-4xl font-bold"
          >
            {title}
          </Heading>
          {standFirst && <StandFirst className="my-6">{standFirst}</StandFirst>}
          {/* Tags dont dont render If alias not available in payload*/}
          <RenderTags tagsWrapper="mb-6" tags={tags} />
          {isArrayWithElements(authors) &&
            authors.map((author) => (
              <AuthorCard
                key={author.name}
                author={author}
                displayHeight={36}
                displayWidth={36}
                imageWrapper="w-[50px]"
                authorCardWrapper="gap-2"
                hasEmail={false}
              />
            ))}
          <Date date={date} time={time} variant={"or"} className="my-2" />

          <div className="flex self-end gap-1 mb-6">
            {socialIconList.map((variant, index) => (
              <ShareSocial
                variant={variant as SocialVariant}
                pagePath={pageUrl}
                pageTitle={title}
                dateCreated={dateCreated}
                key={index}
              />
            ))}
          </div>
          {isArrayWithElements(imageGallery) && (
            <ArticleCarousel
              renderItems={imageGallery}
              sectionName={sectionName}
            />
          )}

          <ArticleParagraph
            contentAccess={contentAccess}
            articleBody={articleBody}
            sectionName={sectionName}
            relatedArticles={relatedArticles}
            latestRegisteredArticle={latestRegisteredArticle}
          />

          {isArrayWithElements(attachments) && (
            <div className="my-md">
              {attachments.map((attachment, idx) => (
                <AttachmentCard key={idx} attachment={attachment} />
              ))}
            </div>
          )}

          <div className="flex self-end gap-1 mt-6">
            {socialIconList.map((variant, index) => (
              <ShareSocial
                variant={variant as SocialVariant}
                pagePath={pageUrl}
                pageTitle={title}
                dateCreated={dateCreated}
                key={index}
              />
            ))}
          </div>
          {urlPath && <Outbrain pathName={urlPath} />}
        </div>

        <SideBlockAds
          sectionName={sectionName}
          className="w-full max-w-[400px] py-xs px-6 sticky top-[130px]"
          adsOnly={true}
          hasImu3={false}
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
      <VideoGallery />
      <PodcastGallery />
    </BHBasePage>
  );
}
