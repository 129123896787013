import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ArticleCarousel.css";

import nextArrow from "@assets/actions/next-white-icon.svg";
import prevArrow from "@assets/actions/prev-white-icon.svg";
import { ImageGalleryCarousel } from "@src/app/types/Opensearch";
import { useRef } from "react";
import Slider, { Settings } from "react-slick";

import ArticleImage from "./ArticleImage";

export type CarouselPropType = {
  renderItems: ImageGalleryCarousel;
  sectionName?: string;
};

function ArticleCarousel({ renderItems, sectionName }: CarouselPropType) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const SliderComponent =
    typeof window === "undefined" ? Slider.default : Slider;

  const sliderRef = useRef<Slider>(null);
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Need to do this as workaroudn known issue, https://github.com/akiran/react-slick/issues/1130
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, currentSlide, slideCount, ...others } = props;
    return <span {...others}>{children}</span>;
  };

  const settings: Settings = {
    infinite: true,
    swipe: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: (
      <SlickButtonFix>
        <button onClick={next}>
          <img src={nextArrow} alt="next-arrow" width={20} height={20} />
        </button>
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <button onClick={previous}>
          <img src={prevArrow} alt="prev-arrow" width={20} height={20} />
        </button>
      </SlickButtonFix>
    ),
  };

  return (
    <>
      {renderItems.length === 1 ? (
        <ArticleImage
          url={renderItems[0].image?.url}
          alt={renderItems[0].id}
          sectionName={sectionName}
          caption={renderItems[0].caption}
          credit={renderItems[0].credit}
        />
      ) : (
        <div className="max-w-[825px]">
          <SliderComponent ref={sliderRef} {...settings}>
            {renderItems.map((item) => (
              <ArticleImage
                key={item.id}
                index={item.id}
                alt={item.id}
                url={item.image?.url}
                caption={item.caption}
                credit={item.credit}
              />
            ))}
          </SliderComponent>
        </div>
      )}
    </>
  );
}

export default ArticleCarousel;
