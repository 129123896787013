import ArticleCardCarousel from "@components/Carousel/ArticleCardCarousel";
import ArticleCard from "@elements/Card/Article/ArticleCard";
import BHBasePage, { type BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import CASBlock from "@src/app/components/CASBlock/CASBlock";
import SideBlockAds from "@src/app/components/Container/SideBlockAds";
import { RenderTags } from "@src/app/components/Elements/Typography/Tags";
import { CAS_CDP_HOME_ID } from "@src/app/helper/getEnvVariables";
import { ProcessedArticleData } from "@src/app/hooks/transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { PayloadResponseType } from "./Home.server";
import HomeMetaData from "./HomeMetaData";
import { HomeSections } from "./HomeSections";

export function HomePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  const renderedArticleIds = useMemo(() => new Set<string>(), []);
  const [excludeIds, setExcludeIds] = useState<string>("");

  const updateRenderedArticleIds = useCallback(
    (articles: ProcessedArticleData[]) => {
      articles.forEach((article) => {
        renderedArticleIds.add(article.id);
      });
      setExcludeIds([...renderedArticleIds].join(","));
    },
    [renderedArticleIds],
  );

  useEffect(() => {
    if (dataLoaderResponse.type === ResponseType.SUCCESS) {
      const { storyQueue, latestRegisteredArticle, latestFreeArticle } =
        dataLoaderResponse.payload;
      updateRenderedArticleIds([
        ...storyQueue,
        ...latestRegisteredArticle,
        ...latestFreeArticle,
      ]);
    }
  }, [dataLoaderResponse, updateRenderedArticleIds]);

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const { storyQueue, latestRegisteredArticle, latestFreeArticle } =
    dataLoaderResponse.payload;
  const topStoryQueue = storyQueue[0];
  const subStoryQueue = [...storyQueue.slice(1)];

  const adsSectionName = "home";
  const { gaData, metaTags } = HomeMetaData(adsSectionName);
  const pageAdTargetValue = PageAdTargetingTypeEnum.HOME;

  const basePageProps: BHBasePageProps = {
    sectionUniqueName: adsSectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="w-full mb-4 lg:border-b lg:border-grey-850">
        <ArticleCard
          displayWidth={833}
          displayHeight={555}
          elements={{
            hasIcon: true,
            hasSectionName: true,
            hasParagraph: true,
            hasTags: false,
          }}
          overrideStyle={{
            imageStyle: "w-full",
            cardWrapper: "flex flex-col-reverse md:flex-row-reverse gap-sm",
            headerWrapper: "w-full md:w-8/12",
            contentWrapper: "w-full md:flex-col md:w-4/12",
            titleStyle: "text-lg md:text-lg leading-lg font-semibold md:mb-2",
            tagWrapper: "hidden md:flex md:py-2",
            description: "hidden md:block",
          }}
          htmlElements={{
            headingElement: "h1",
          }}
          {...topStoryQueue}
        />
        <div className="flex flex-col">
          <span
            data-testid="paragraph"
            className={
              "font-primary text-xss md:text-xs leading-[150%] font-regular mb-sm md:hidden"
            }
          >
            {topStoryQueue.paragraph}
          </span>
          <RenderTags
            tags={topStoryQueue.tags}
            tagsWrapper="flex gap-2 md:hidden"
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex flex-col md:flex-row w-full gap-4">
          {subStoryQueue.map((context) => {
            return (
              <div
                className="w-full lg:border-b lg:border-grey-850"
                key={context.id}
              >
                <ArticleCard
                  displayWidth={454}
                  displayHeight={303}
                  elements={{
                    hasIcon: true,
                    hasSectionName: true,
                    hasParagraph: true,
                    hasTags: false,
                    hasDateSideBySide: true,
                  }}
                  overrideStyle={{
                    headerWrapper: "basis-4/12",
                    contentWrapper: "basis-8/12",
                    imageStyle: "w-full",
                    cardWrapper: "flex md:flex-col gap-2 md:gap-4",
                    sectionNameWrapper: "flex-col-reverse items-start gap-1",
                    tagWrapper: "hidden lg:flex gap-2",
                    dateStyle: "font-regular lg:text-xs",
                    description: "hidden md:flex",
                    hero: "mb-0",
                  }}
                  {...context}
                />
              </div>
            );
          })}
        </div>
        <SideBlockAds
          sectionName={adsSectionName}
          adsOnly={true}
          hasImu3={false}
          className="min-w-[300px] w-auto"
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
      <ArticleCardCarousel
        renderItems={latestRegisteredArticle}
        className="mt-sm"
      />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-lg mt-xxl">
        {latestFreeArticle.map((context) => {
          return (
            <React.Fragment key={context.id}>
              <div className="col-span-1">
                <ArticleCard
                  displayWidth={282}
                  displayHeight={188}
                  elements={{
                    hasIcon: true,
                    hasSectionName: true,
                    hasParagraph: true,
                    hasDateSideBySide: true,
                  }}
                  overrideStyle={{
                    headerWrapper: "basis-4/12",
                    contentWrapper: "basis-8/12",
                    cardWrapper: "flex md:flex-col gap-2 w-full",
                    imageStyle: "w-full",
                    sectionNameWrapper: "flex-col-reverse items-start gap-1",
                    tagWrapper: "hidden lg:flex",
                    dateStyle: "font-regular text-grey-400",
                    description: "hidden sm:flex",
                    sectionNameItem: "lg:justify-start",
                    titleStyle: "text-sm",
                  }}
                  {...context}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>

      <CASBlock casId={CAS_CDP_HOME_ID} />

      {excludeIds && (
        <HomeSections
          adsSectionName={adsSectionName}
          renderedArticleIds={excludeIds}
        />
      )}
    </BHBasePage>
  );
}
