import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { useEffect, useRef, useState } from "react";

export function useAdvertLoaded(type: AdvertisementTypeEnum, id?: string) {
  const [advertLoaded, setAdvertLoaded] = useState(false);
  const targetNodeRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const targetNodeId = id || generateAdvertElementId(type);
    const callBack = () => {
      if (
        targetNodeRef.current?.children[0]?.firstElementChild?.tagName ===
        "IFRAME"
      ) {
        setAdvertLoaded(true);
      }
    };

    targetNodeRef.current = document.getElementById(targetNodeId);
    const observer = new MutationObserver(callBack);

    if (targetNodeRef.current) {
      observer.observe(targetNodeRef.current, {
        childList: true,
        subtree: true,
      });
    }
  }, [id, type, setAdvertLoaded]);

  return advertLoaded;
}

export const generateAdvertElementId = (type: AdvertisementTypeEnum) => {
  return `dfp-ad-${type}`;
};

/**
 * Generate the Google Ads slot id based on the parent section unique name.
 * In-article ads is having a different slot prefix with `midarticlespecial`
 */
export const GoogleAdsSlotFactory = {
  prefix: "/5908/bh",
  midarticlespecial: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/midarticlespecial/${sectionUniqueName}`;
  },
  imu1: (sectionUniqueName?: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu1/${sectionUniqueName}`;
  },
  imu2: (sectionUniqueName?: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu2/${sectionUniqueName}`;
  },
  imu3: (sectionUniqueName?: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu3/${sectionUniqueName}`;
  },
  lb1: (sectionUniqueName?: string) => {
    return null != sectionUniqueName
      ? `${GoogleAdsSlotFactory.prefix}/lb1/${sectionUniqueName}`
      : `${GoogleAdsSlotFactory.prefix}/lb1`;
  },
  lb2: (sectionUniqueName?: string) => {
    return null != sectionUniqueName
      ? `${GoogleAdsSlotFactory.prefix}/lb1/${sectionUniqueName}`
      : `${GoogleAdsSlotFactory.prefix}/lb1`;
  },
  prestitial: (sectionUniqueName?: string) => {
    return null != sectionUniqueName
      ? `${GoogleAdsSlotFactory.prefix}/prestitial/${sectionUniqueName}`
      : `${GoogleAdsSlotFactory.prefix}/prestitial`;
  },
  catfish: (sectionUniqueName?: string) => {
    return null != sectionUniqueName
      ? `${GoogleAdsSlotFactory.prefix}/catfish/${sectionUniqueName}`
      : `${GoogleAdsSlotFactory.prefix}/catfish`;
  },
};
