import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";

export function getContentCat(contentAccess: LabelCardIconVariety): 1 | 2 | 3 {
  switch (contentAccess) {
    case LabelCardIconVariety.FREE:
      return 1;
    case LabelCardIconVariety.PREMIUM:
      return 2;
    case LabelCardIconVariety.REGISTERED_USER:
      return 3;
    default:
      return 1;
  }
}
