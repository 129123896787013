import CASBlock from "@components/CASBlock/CASBlock";
import MySphLoginButton from "@components/Header/MySphLoginButton";
import { CAS_CDP_PAYWALL_ID } from "@helper/getEnvVariables";
import useCasScript from "@hooks/useCasScript";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";

export default function SubscriptionBanner() {
  const { isScriptReady: isCasScriptReady } = useCasScript();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;

  return (
    <div>
      <div className="relative">
        <div className="absolute bg-white-100 bg-opacity-70 w-full h-[120px] bottom-8"></div>
        <div className="flex text-md items-center gap-1 px-xs">
          {userDisplayName ? (
            <span className="text-black-100">
              Hello {userDisplayName}! Pilih pilihan pembelian anda di bawah:
            </span>
          ) : (
            <>
              <MySphLoginButton
                buttonText="Log masuk"
                iconFont={true}
                textStyle="text-blue-100 underline"
              />
              <span className="text-black-100">untuk terus membaca</span>
            </>
          )}
        </div>
      </div>
      {isCasScriptReady && (
        <CASBlock
          key={`cas-${CAS_CDP_PAYWALL_ID}`}
          casId={CAS_CDP_PAYWALL_ID}
        />
      )}
    </div>
  );
}
