import { Helmet } from "react-helmet-async";

import {
  ANDROID_PACKAGE,
  APP_NAME,
  FB_APP_ID,
  FB_PAGES,
  IOS_APP_STORE_ID,
  ITUNES_APP,
  OG_SITE_NAME,
  ROBOTS,
  TWITTER_CARD,
  URL_ORIGIN,
} from "./MetaTagsConstants";

export type MetaTagsProps = {
  title: string;
  description?: string;
  slug: string;
  shortLink?: string;
  ogType?: string;
  articlePageclass?: string;
  image?: string;
  publishedTime?: string;
  modifiedTime?: string;
  keywords?: string[];
  author?: string;
  section?: string;
  isArticlePage?: boolean;
  addAppleMobileTags?: boolean;
};

export default function MetaTags({
  title,
  description,
  slug,
  shortLink,
  ogType,
  articlePageclass,
  image,
  publishedTime,
  modifiedTime,
  keywords = [],
  author,
  section,
  isArticlePage = false,
  addAppleMobileTags = false,
}: MetaTagsProps) {
  const canonicalUrl: string = URL_ORIGIN + (slug === "home" ? "" : slug);
  const shortLinkUrl: string = URL_ORIGIN + shortLink;

  return (
    <Helmet>
      <title>{title}</title>
      {/* Search Engine tags */}
      <meta name="robots" content={ROBOTS} />
      <link rel="canonical" href={canonicalUrl} />
      {shortLink && <link rel="shortlink" href={shortLinkUrl} />}
      {description && <meta name="description" content={description} />}
      {keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(", ")} />
      )}
      {articlePageclass && (
        <meta name="article:pageclass" content={articlePageclass} />
      )}

      {/* Open Graph tags */}
      <meta property="og:site_name" content={OG_SITE_NAME} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      {ogType && <meta property="og:type" content={ogType} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta property="og:image:url" content={image} />}
      {image && <meta property="og:image:secure" content={image} />}

      {/* Article tags */}
      {isArticlePage && (
        <>
          <meta property="article:author" content={author} />
          <meta property="article:section" content={section} />
          {keywords.map((keyword, index) => (
            <meta key={index} property="article:tag" content={keyword} />
          ))}
          <meta property="article:published_time" content={publishedTime} />
          <meta property="article:modified_time" content={modifiedTime} />
        </>
      )}

      {/* Twitter tags */}
      <meta name="twitter:card" content={TWITTER_CARD} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={canonicalUrl} />
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}

      {/* App links metadata */}
      <meta property="fb:pages" content={FB_PAGES} />
      <meta property="fb:app_id" content={FB_APP_ID} />

      <meta data-rh="true" property="al:ios:url" content={canonicalUrl} />
      <meta
        data-rh="true"
        property="al:ios:app_store_id"
        content={IOS_APP_STORE_ID}
      />
      <meta data-rh="true" name="apple-itunes-app" content={ITUNES_APP} />
      <meta data-rh="true" property="al:ios:app_name" content={APP_NAME} />
      <meta property="al:android:url" content={canonicalUrl} />
      <meta property="al:android:package" content={ANDROID_PACKAGE} />
      <meta name="google-play-app" content={ANDROID_PACKAGE} />
      <meta property="al:android:app_name" content={APP_NAME} />
      <meta property="al:web:url" content={canonicalUrl} />

      {addAppleMobileTags && (
        <meta name="apple-mobile-web-app-capable" content="yes" />
      )}
      {addAppleMobileTags && (
        <meta name="apple-mobile-web-app-title" content="Berita Harian" />
      )}
    </Helmet>
  );
}
