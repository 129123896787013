import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@typings/PageMetaData";

type UGCListingPageMetaDataProps = {
  sectionName: string;
  pageUrl: string;
  campaignTitle: string;
};
export default function UGCListingPageMetaData({
  sectionName,
  pageUrl,
  campaignTitle,
}: UGCListingPageMetaDataProps): PageMetaDataValues {
  const metaTags: MetaTagsProps = {
    title: `Berita ${capitalize(sectionName)} - Berita Terkini & Popular | Beritaharian.sg`,
    description: "Baca dan dapatkan Kempen terkini hanya di BeritaHarian.sg",
    slug: pageUrl,
  };

  const gaData: GADataProps = {
    level2: sectionName,
    title: `${capitalize(campaignTitle)}_Index`,
    contentcat: 1,
    chapter1: capitalize(campaignTitle),
    section: sectionName,
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
