import SideBlockAds from "@container/SideBlockAds";
import Iframe from "@elements/Iframe/Iframe";
import type { BHBasePageProps } from "@pages/BHBasePage";
import BHBasePage from "@pages/BHBasePage";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

export default function KenaliKami() {
  const sectionName = "Kenali Kami";
  const uniqueSectionName = "kenali-kami";
  const basePageProps: BHBasePageProps = {
    sectionUniqueName: uniqueSectionName,
    gaData: {
      level2: "miscellaneous",
      title: sectionName,
      section: "miscellaneous",
      contentcat: 1,
      contenttype: "listing",
    },
    metaTags: {
      title: "Kenali Kami, Berita - BeritaHarian.sg",
      slug: uniqueSectionName,
      description: "Berita - . Baca lebih lanjut di BeritaHarian.sg",
    },
    pageAdTargetValue: PageAdTargetingTypeEnum.HOME,
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="flex flex-col lg:flex-row">
        <div className="flex lg:px-sm flex-col w-12/12 lg:w-8/12 w-full">
          <h1 className="flex text-lg font-semibold mt-0 mb-12">Kenali Kami</h1>
          <Iframe
            title="Kenali Kami"
            src="//www.youtube.com/embed/Sz4QGmXHL5I"
            width="w-full"
          />
          <p className="text-xs mt-4 mb-12">
            Berita Harian mula diterbitkan di Singapura pada 1 Julai 1957.
            Sebagai sebuah akhbar utama masyarakat Melayu/Islam, Berita Harian
            berperanan dalam menyampaikan maklumat dan berita secara tepat dan
            menyeluruh. Setelah lebih sedekad di alam siber, cyBerita melalui
            penjenamaan semula dan kini dikenali sebagai BeritaHarian.sg yang
            menawarkan kepelbagaian demi menepati cita rasa khalayak pembaca
            yang pelbagai. Berita Harian kini boleh didapati menerusi pelbagai
            wadah digital, termasuk tablet dan telefon bijak. Segalanya demi
            kemudahan pembaca, segalanya di hujung jari anda.
          </p>
        </div>
        <SideBlockAds
          pageAdTargetingProps={PageAdTargetingTypeEnum.LISTING}
          sectionName={uniqueSectionName}
          adsOnly={true}
        />
      </div>
    </BHBasePage>
  );
}
