import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@src/app/types/PageMetaData";

export type SectionMetaDataProps = {
  sectionName: string;
  parentSection: string | undefined;
  paywall: string | null;
};

export function SectionMetaData({
  sectionName,
  parentSection,
  paywall,
}: SectionMetaDataProps): PageMetaDataValues {
  let capitalizeSectionName = capitalize(sectionName.replace("-", " "));
  if (paywall != null || sectionName != "gaya-hidup") {
    capitalizeSectionName = capitalizeSectionName.replace(" ", "_");
  }

  let metaTitle = `Berita ${capitalizeSectionName} - Berita Terkini & Popular | Beritaharian.sg`;
  let metaDescription: string | undefined =
    `Berita ${capitalizeSectionName} - Baca dan dapatkan Berita ${capitalizeSectionName} terkini & popular hanya di BeritaHarian.sg`;
  if (paywall != null) {
    metaTitle =
      "BeritaHarian - Berita Terkini, Singapura, Ekoniaga, Dunia & Sukan";
    switch (sectionName) {
      case "akses-percuma":
        metaDescription =
          "Berita Harian Singapura - Dapatkan berita percuma, laporan eksklusif, analisis mendalam dan kandungan berbilang media mengenai Singapura, Asia dan dunia.";
        break;
      case "premium-articles":
        metaDescription =
          "Berita Harian Singapura - Dapatkan berita premium, laporan eksklusif, analisis mendalam dan kandungan berbilang media mengenai Singapura, Asia dan dunia.";
        break;
      case "registered":
        metaDescription =
          "Berita Harian Singapura - Daftar diri untuk dapatkan berita premium, laporan eksklusif, analisis mendalam dan kandungan berbilang media mengenai Singapura, Asia dan dunia.";
        break;
      default:
        metaDescription = undefined;
        break;
    }
  } else if (parentSection !== undefined) {
    metaTitle = `Berita ${capitalize(parentSection)} - Berita Terkini & Popular | Beritaharian.sg`;
    switch (sectionName) {
      case "mahligai":
        metaDescription =
          "Mahligai Gah! - Baca dan dapatkan berita perkahwinan dan rumah tangga terkini & popular hanya di BeritaHarian.sg";
        break;
      case "wanita":
        metaDescription =
          "Wanita Gah! - Baca dan dapatkan berita Gaya Artistik Hiburan Wanita Gah! terkini & popular hanya di BeritaHarian.sg";
        break;
      case "hiburan":
        metaDescription =
          "Hiburan Gah! - Baca dan dapatkan berita hiburan terkini & popular hanya di BeritaHarian.sg";
        break;
      default:
        metaDescription = `${capitalizeSectionName} ${parentSection}! Baca dan dapatkan berita ${capitalizeSectionName} terkini & popular hanya di BeritaHarian.sg`;
        break;
    }
  }

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: parentSection ? `${parentSection}/${sectionName}` : sectionName,
  };

  const level2 = parentSection
    ? parentSection.replace("-", " ")
    : sectionName.replace("-", " ");

  const gaData: GADataProps = {
    level2: level2,
    title: `${capitalizeSectionName}_Index`,
    section: level2,
    chapter1: parentSection && capitalizeSectionName,
    contentcat: 1,
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
