export type TagPropType = {
  value?: string;
  destination?: string;
  id?: string;
};

export default function Tags({ value, destination, id }: TagPropType) {
  return (
    <span
      data-testid={id}
      className="text-nowrap bg-white-200 text-black-400 text-xxs lg:text-sm italic hover:underline p-1.5"
    >
      <a href={`tags${destination}`}>{value}</a>
    </span>
  );
}

export type TagsProps = {
  tagsWrapper?: string;
  tags?: {
    alias?: string;
    name?: string;
  }[];
};
import { twMerge } from "tailwind-merge";

export const RenderTags = ({ tags, tagsWrapper }: TagsProps) => {
  return Array.isArray(tags) && tags[0]?.alias ? (
    <div
      className={twMerge("flex gap-2 flex-wrap", tagsWrapper)}
      data-testid="tag-wrapper"
    >
      {tags.map(({ alias, name }, index) =>
        alias ? (
          <Tags
            key={index}
            destination={alias}
            value={name}
            id={`tag-${index}`}
          />
        ) : null,
      )}
    </div>
  ) : null;
};
