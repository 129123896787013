import { UrlMatch } from "@app/data.ts";
import { getOsBySection } from "@caas/SectionPageQueries";
import { SectionPagePaywallQuery } from "@caas/SectionPageQueries";
import { removeTrailingSlash } from "@helper/removeTrailingLeadingSlash";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { LabelCardIconVariety } from "@src/app/components/Icon/LabelCardIcon";
import fetchBHApi from "@src/app/helper/fetchBHApi";
import fetchCass from "@src/app/helper/fetchCaas";
import {
  isValidSection,
  SectionToUniqueNameMapping,
} from "@src/app/helper/validation";
import getOSProcessedArticleData, {
  type ProcessedArticleData,
} from "@transformer/useOSResponse";
import { RouteParam, SectionRouteParam } from "@typings/Data";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import type {
  OSResponse,
  PartialCueArticleWithCampaign,
} from "@typings/Opensearch";
import { Match, MatchResult } from "path-to-regexp";

export type SectionPayloadResponseType = {
  data: ProcessedArticleData[];
  paywall: LabelCardIconVariety | null;
  section: string;
};

export const isSectionMatchResult = (
  a: Match,
): a is MatchResult<SectionRouteParam> => {
  return "level1" in ((a as MatchResult).params as RouteParam);
};

export const fetchSectionData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithRedirect<SectionPayloadResponseType, string>
> => {
  const section = isSectionMatchResult(urlComponent)
    ? urlComponent.params.level1
    : (removeTrailingSlash(urlComponent.path).split("/").at(-1) as string);

  let paywall: LabelCardIconVariety | null = null;
  let isPaywall = true;
  switch (section) {
    case "akses-percuma":
      paywall = LabelCardIconVariety.FREE;
      break;
    case "premium-articles":
      paywall = LabelCardIconVariety.PREMIUM;
      break;
    case "registered":
      paywall = LabelCardIconVariety.REGISTERED_USER;
      break;
    default:
      isPaywall = false;
      break;
  }

  try {
    let data;
    if (isPaywall && paywall != null) {
      data = (await fetchCass({
        searchQuery: SectionPagePaywallQuery(paywall, 9),
      })) as OSResponse<PartialCueArticleWithCampaign>;
    } else {
      if (!isValidSection(section)) {
        return { type: ResponseType.CLIENT_ERROR, statusCode: 400 };
      }
      const sectionUniqueName = SectionToUniqueNameMapping[section];
      data = (await fetchCass({
        searchQuery: getOsBySection({
          sectionUniqueName: sectionUniqueName,
          size: 9,
        }),
      })) as OSResponse<PartialCueArticleWithCampaign>;
    }

    const hits = data.payload.hits.total.value;
    if (hits === 0) {
      return { type: ResponseType.CLIENT_ERROR, statusCode: 404 };
    }

    const processedData = getOSProcessedArticleData(data);

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        data: processedData,
        paywall: isPaywall ? paywall : null,
        section: section,
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchMoreStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  const response: ProcessedArticleData[] = await fetchBHApi(
    "more-section-stories",
    "POST",
    {
      section: requestData.filterArticlesBasedOn,
      sort: requestData.sort,
      size: 8,
    },
  );

  return response;
}

export async function fetchMorePaywallStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[]> {
  const response: ProcessedArticleData[] = await fetchBHApi(
    "more-section-paywall-stories",
    "POST",
    {
      contentAccess: requestData.filterArticlesBasedOn,
      sort: requestData.sort,
      size: 8,
    },
  );

  return response;
}
