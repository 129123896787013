import SideBlockAds from "@container/SideBlockAds";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import LoadMore from "@elements/LoadMore/LoadMore";
import AudioPlayer from "@elements/Player/PodcastPlayer";
import type { BHBasePageProps } from "@pages/BHBasePage";
import BHBasePage from "@pages/BHBasePage";
import fetchBHApi from "@src/app/helper/fetchBHApi";
import { isArrayWithElements } from "@src/app/helper/utils";
import useSwrApi from "@src/app/hooks/useSwrApi";
import { type PodcastProcessedResponse } from "@transformer/usePodcastResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import PodcastMetaData from "./PodcastMetaData";

export default function PodcastPage() {
  const { data } = useSwrApi<PodcastProcessedResponse[]>("podcasts");
  const { level1: id } = useParams();
  const [podcasts, setPodcasts] = useState<PodcastProcessedResponse[]>();
  const [size, setSize] = useState(20);
  const [playing, setPlaying] = useState<
    PodcastProcessedResponse | undefined
  >();

  useEffect(() => {
    const fetchData = async () => {
      if (!data || data.length === 0) return;
      let nowPlaying = false;
      try {
        const podcast: PodcastProcessedResponse[] = id
          ? await fetchBHApi<PodcastProcessedResponse[]>( // eslint-disable-next-line prettier/prettier
              `podcasts/${id}`, // eslint-disable-next-line prettier/prettier
              "GET", // eslint-disable-next-line prettier/prettier
            )
          : data;

        setPlaying(podcast[0]);
        nowPlaying = id ? false : true;
      } catch (error) {
        nowPlaying = true;
        setPlaying(data[0]);
      }

      const updatedPodcasts = data.map((podcast, index) =>
        index === 0
          ? { ...podcast, nowPlaying: nowPlaying ? true : false }
          : podcast,
      );
      setPodcasts(updatedPodcasts);
    };

    void fetchData();
  }, [id, data]);

  const handleNowPlaying = useMemo(
    () => (id: string) => {
      setPodcasts(
        (prevValue) =>
          prevValue &&
          prevValue.map((video) => ({
            ...video,
            nowPlaying: video.id === id,
          })),
      );
    },
    [setPodcasts],
  );

  const handleOnClickThumbnail = useCallback(
    (props: PodcastProcessedResponse) => {
      // eslint-disable-next-line react/prop-types
      const { id } = props;
      handleNowPlaying(id);
      setPlaying(props);
    },
    [handleNowPlaying, setPlaying],
  );

  const handleLoadMorePodcasts = async () => {
    const morePodcasts = await fetchBHApi<PodcastProcessedResponse[]>(
      `podcasts?size=${size}`,
      "GET",
    );
    setPodcasts((prevPodcast) => {
      const combinedPodcasts = [...(prevPodcast || []), ...morePodcasts];

      const uniquePodcastsMap = new Map();
      combinedPodcasts.forEach((podcast) =>
        uniquePodcastsMap.set(podcast.id, podcast),
      );
      const uniquePodcasts = Array.from(uniquePodcastsMap.values());
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return uniquePodcasts;
    });

    setSize((prevValue) => prevValue + 10);

    // This avoids GTM push.
    return 0;
  };

  const sectionName = "podcasts";
  const { gaData, metaTags } = PodcastMetaData(sectionName);
  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    sectionUniqueName: sectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="flex flex-col lg:flex-row">
        <div className="flex lg:px-sm flex-col w-full">
          <span className="font-secondary text-blue-300 text-lg font-semimedium">
            Podcast
          </span>
          {playing && <AudioPlayer {...playing} height="200" />}
          <div className="w-full border-b border-grey-850 pt-sm">
            <span className="text-md font-secondary lg:text-lg text-grey-400">
              {`Semua Podcast (`}
              <span className="text-xs text-grey-400 px-1">100 Episod</span>
              {`)`}
            </span>
          </div>
          {isArrayWithElements(podcasts) &&
            podcasts.map((podcast, index) => (
              <ThumbnailCard<PodcastProcessedResponse>
                variant="podcast"
                key={index}
                handleOnClickThumbnail={handleOnClickThumbnail}
                {...podcast}
              />
            ))}
          <div className="my-md">
            <LoadMore
              onLoadMore={handleLoadMorePodcasts}
              loadText="Laporan lain podcasts"
            />
          </div>
        </div>
        <SideBlockAds
          sectionName={sectionName}
          adsOnly={true}
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
    </BHBasePage>
  );
}
