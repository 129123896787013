import SideBlockAds from "@container/SideBlockAds";
import type { BHBasePageProps } from "@pages/BHBasePage";
import BHBasePage from "@pages/BHBasePage";

export default function HubungiKami() {
  const sectionName = "Hubungi Kami";
  const uniqueSectionName = "hubungi-kami";
  const basePageProps: BHBasePageProps = {
    sectionUniqueName: uniqueSectionName,
    gaData: {
      level2: "miscellaneous",
      title: sectionName,
      section: "miscellaneous",
      contentcat: 1,
      contenttype: "listing",
    },
    metaTags: {
      title: "Hubungi Kami, Berita - BeritaHarian.sg",
      slug: uniqueSectionName,
      description: "Berita - . Baca lebih lanjut di BeritaHarian.sg",
    },
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="flex flex-col lg:flex-row">
        <div className="flex lg:px-sm flex-col w-12/12 lg:w-8/12 w-full">
          <h1 className="flex text-lg font-semibold mt-0 mb-12">
            Hubungi Kami
          </h1>
          <p className="font-primary text-xxs">
            Anda ada maklumat mengenai sesuatu berita menarik?
            <br />
            Hubungi hotline: 1800 8227272
            <br />
            <a
              className="no-underline text-blue-300 hover:text-blue-800 hover:underline"
              href="mailto:bhnews@sph.com.sg"
            >
              E-mel: bhnews@sph.com.sg
            </a>
          </p>
          <p>&nbsp;</p>
          <div className="table border-collapse">
            <div className="table-header-group">
              <div className="table-cell font-semibold text-[12.852px] w-[110.825px]">
                Jabatan
              </div>
              <div className="table-cell font-semibold text-[12.852px] w-[110.825px]">
                Nombor telefon
              </div>
              <div className="table-cell font-semibold text-[12.852px]">
                E-mel
              </div>
            </div>
            <div className="table-row-group text-[12.852px] font-regular">
              <div className="table-row">
                <div className="table-cell">Meja Berita</div>
                <div className="table-cell">6319 5601</div>
                <div className="table-cell">
                  <a
                    className="no-underline text-blue-300 hover:underline"
                    href="mailto:bhnews@sph.com.sg"
                  >
                    bhnews@sph.com.sg
                  </a>
                </div>
              </div>
            </div>
            <div className="table-row-group text-[12.852px] font-regular">
              <div className="table-row">
                <div className="table-cell">Meja Digital</div>
                <div className="table-cell">6319 5126</div>
                <div className="table-cell">
                  <a
                    className="no-underline text-blue-300 hover:underline"
                    href="mailto:bhonline@sph.com.sg"
                  >
                    bhonline@sph.com.sg
                  </a>
                </div>
              </div>
            </div>
            <div className="table-row-group text-[12.852px] font-regular">
              <div className="table-row">
                <div className="table-cell">Pertanyaan</div>
                <div className="table-cell">6319 8611</div>
                <div className="table-cell">
                  <a
                    className="no-underline text-blue-300 hover:underline"
                    href="https://www.sph.com.sg/contact-us/?ctopic=advertising&amp;csubject=general%20advertising"
                  >
                    Klik di sini
                  </a>
                </div>
              </div>
            </div>
            <div className="table-row-group text-[12.852px] font-regular">
              <div className="table-row">
                <div className="table-cell">Khidmat Pelanggan</div>
                <div className="table-cell">6388 3838</div>
                <div className="table-cell">
                  <a
                    className="no-underline text-blue-300 hover:underline"
                    href="mailto:circs@sph.com.sg"
                  >
                    circs@sph.com.sg
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SideBlockAds sectionName={uniqueSectionName} adsOnly={true} />
      </div>
    </BHBasePage>
  );
}
