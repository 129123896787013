import { UrlMatch } from "@app/data.ts";
import {
  getCampaignRegularByUrlPath,
  getCampaignThematicByUrlPath,
  UGCListingPageQuery,
} from "@caas/UgcPageQueries";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import fetchBHApi from "@src/app/helper/fetchBHApi";
import fetchCass from "@src/app/helper/fetchCaas";
import getOSProcessedArticleData, {
  ProcessedArticleData,
} from "@transformer/useOSResponse";
import { RouteParam, SectionRouteParam } from "@typings/Data";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import type {
  OSResponse,
  PartialCueArticleWithCampaign,
} from "@typings/Opensearch";
import { Match, MatchResult } from "path-to-regexp";

export type UGCListingPageResponse = {
  data: ProcessedArticleData[];
  urlAlias: string;
  isCampignValid?: boolean;
};

export const isSectionMatchResult = (
  a: Match,
): a is MatchResult<SectionRouteParam> => {
  return "level1" in ((a as MatchResult).params as RouteParam);
};

export const fetchUGCListingData = async ({
  urlComponent,
}: UrlMatch): Promise<TRouteWithRedirect<UGCListingPageResponse, string>> => {
  const urlPath = isSectionMatchResult(urlComponent)
    ? urlComponent.params.level1
    : "";

  try {
    const data = (await fetchCass({
      searchQuery: UGCListingPageQuery({ urlPath: urlPath }),
    })) as OSResponse<PartialCueArticleWithCampaign>;

    const thematic = (await fetchCass({
      searchQuery: getCampaignThematicByUrlPath({ urlPath: urlPath }),
    })) as OSResponse<PartialCueArticleWithCampaign>;

    const regular = (await fetchCass({
      searchQuery: getCampaignRegularByUrlPath({ urlPath: urlPath }),
    })) as OSResponse<PartialCueArticleWithCampaign>;

    const campaign =
      thematic.payload.hits.total.value === 0 ? regular : thematic;

    if (campaign.payload.hits.total.value === 0) {
      return { type: ResponseType.CLIENT_ERROR, statusCode: 404 };
    }

    if (
      data.payload.hits.total.value === 0 &&
      campaign.payload.hits.total.value !== 0
    ) {
      return {
        type: ResponseType.SUCCESS,
        statusCode: 200,
        payload: {
          data: getOSProcessedArticleData(campaign),
          urlAlias: urlPath,
          isCampignValid: true,
        },
      };
    }

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        data: getOSProcessedArticleData(data),
        urlAlias: urlPath,
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchMoreCampaignStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  const response: ProcessedArticleData[] = await fetchBHApi(
    "more-ugc-campaign-stories",
    "POST",
    {
      urlPath: requestData.filterArticlesBasedOn,
      sort: requestData.sort,
    },
  );

  return response;
}
