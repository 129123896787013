import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import ArticleCardCarousel from "@components/Carousel/ArticleCardCarousel";
import ArticleImage from "@components/Carousel/ArticleImage";
import CASBlock from "@components/CASBlock/CASBlock";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import { AdsImu1 } from "@elements/Advertisement/variant/AdsImu";
import RelatedArticle from "@elements/RelatedArticle/RelatedArticle";
import Paragraph from "@elements/Typography/Paragraph";
import { CAS_CDP_REGWALL_ID } from "@helper/getEnvVariables";
import { getUserType } from "@helper/mySPH";
import { isArrayWithElements } from "@helper/utils";
import useCasScript from "@hooks/useCasScript";
import { type ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import {
  type ProcessedArticleBody,
  type RelatedArticles,
} from "@typings/Opensearch";
import React, { useEffect, useState } from "react";

import ApplyParagraphAnnotations from "./ApplyParagraphAnnotations";
import { YOUTUBE_WATCH_URL } from "./Embeds/constants";
import Embed from "./Embeds/Embed";
import YouTubeEmbedCustom from "./Embeds/YouTubeEmbedCustom";
import GetLimitedParagraphsFromArticleBody from "./GetLimitedParagraphsFromArticleBody";
import ParagraphList from "./ParagraphElements/ParagraphList";
import PullQuoteCard from "./ParagraphElements/PullQuoteCard";
import SubHead from "./ParagraphElements/SubHead";
import WrapperContainer from "./ParagraphElements/WrapperContainer";
import RegistrationBanner from "./RegistrationBanner";
import SubscriptionBanner from "./SubscriptionBanner";

export type ArticleParagraphProps = {
  contentAccess: LabelCardIconVariety;
  articleBody: ProcessedArticleBody;
  sectionName: string | undefined;
  relatedArticles: RelatedArticles;
  latestRegisteredArticle: ProcessedArticleData[];
};

export default function ArticleParagraph({
  contentAccess,
  articleBody,
  sectionName,
  relatedArticles,
  latestRegisteredArticle,
}: ArticleParagraphProps) {
  const [showSubBanner, setShowSubBanner] = useState<boolean>(false);
  const [showRegBanner, setShowRegBanner] = useState<boolean>(false);
  const [trimmedParagraphs, setTrimmedParagraphs] =
    useState<ProcessedArticleBody>(
      GetLimitedParagraphsFromArticleBody(articleBody, 2),
    );
  const [showNormalBanner, setShowNormalBanner] = useState<boolean>(false);
  const { isScriptReady: isCasScriptReady } = useCasScript();

  const userType = getUserType();

  useEffect(() => {
    if (
      contentAccess === LabelCardIconVariety.PREMIUM &&
      userType !== "y-sub"
    ) {
      setShowSubBanner(true);
    } else if (
      contentAccess === LabelCardIconVariety.REGISTERED_USER &&
      (userType === "y-anoy" || userType === undefined)
    ) {
      setShowRegBanner(true);
    } else if (userType !== "y-sub") {
      setTrimmedParagraphs(articleBody);
      setShowNormalBanner(true);
    } else {
      setTrimmedParagraphs(articleBody);
    }
  }, [contentAccess, articleBody, userType]);

  let paragraphCount = 0;

  return (
    <div data-testid="article-body">
      {trimmedParagraphs.map((element, index) => {
        let bannersAndAds = null;

        if (paragraphCount === 1) {
          bannersAndAds = (
            <>
              {showSubBanner && <SubscriptionBanner />}
              {showRegBanner && <RegistrationBanner />}
              <AdsImu1
                pageAdTargetType={PageAdTargetingTypeEnum.ARTICLE}
                sectionName={sectionName}
              />
            </>
          );
        } else if (
          paragraphCount === 6 &&
          contentAccess === LabelCardIconVariety.FREE
        ) {
          bannersAndAds = (
            <div className="max-w-[825px] mb-8">
              <ArticleCardCarousel
                renderedCount={3}
                renderItems={latestRegisteredArticle}
                className="mt-sm"
              />
            </div>
          );
        }

        switch (element.type) {
          case "paragraph":
            paragraphCount++;
            return (
              <React.Fragment key={index}>
                <Paragraph testid={`paragraph-${index}`}>
                  {ApplyParagraphAnnotations(
                    element.value,
                    element.annotations,
                  )}
                </Paragraph>
                {bannersAndAds}
              </React.Fragment>
            );

          case "sub_head":
            return (
              <React.Fragment key={index}>
                <SubHead element={element} />
              </React.Fragment>
            );

          case "image": {
            const imageHeight =
              element.image?.height && element.image.height >= 1460
                ? 1460
                : 550;
            return (
              <ArticleImage
                key={index}
                index={index.toString()}
                url={element.image?.url}
                alt={element.caption}
                caption={element.caption}
                credit={element.credit}
                height={imageHeight}
                className="my-12"
              />
            );
          }

          case "embed":
            return (
              <React.Fragment key={index}>
                <Embed className="my-lg" src={element.embedUrl} />
              </React.Fragment>
            );

          case "pull_quote":
            return (
              <React.Fragment key={index}>
                <PullQuoteCard
                  quote={element.quote}
                  quotee={element.quotee}
                  quoteLabel={element.quoteLabel}
                  quoteCredit={element.quoteCredit}
                />
              </React.Fragment>
            );

          case "list_bulleted":
          case "list_numbered": {
            const listType = element.type === "list_bulleted" ? "ol" : "ul";
            const listItems = element.listItem;
            const listStyle =
              element.listStyle === "decimal" ? "list-decimal" : "list-disc";
            return (
              <React.Fragment key={index}>
                <ParagraphList
                  listStyle={listStyle}
                  listType={listType}
                  listItems={listItems}
                />
              </React.Fragment>
            );
          }

          case "youtube_video": {
            const url = `${YOUTUBE_WATCH_URL}${element.youtubeId}`;
            return (
              <React.Fragment key={index}>
                <YouTubeEmbedCustom url={url} className="my-lg" />
              </React.Fragment>
            );
          }

          case "brightcove_video": {
            return (
              <React.Fragment key={index}>
                <WrapperContainer className="block my-lg">
                  <BrightcoveVideo videoId={element.videoId} />
                </WrapperContainer>
              </React.Fragment>
            );
          }

          default:
            return null;
        }
      })}
      {isArrayWithElements(relatedArticles) && (
        <RelatedArticle relatedArticles={relatedArticles} />
      )}
      {showNormalBanner && isCasScriptReady && (
        <CASBlock
          key={`cas-${CAS_CDP_REGWALL_ID}`}
          casId={CAS_CDP_REGWALL_ID}
        />
      )}
    </div>
  );
}
