import dummyImage from "@assets/images/author.svg";
import mailIcon from "@assets/images/author-email.svg";
import { Image } from "@elements/ResponsiveImage/Image";
import { ProcessedAuthorContent } from "@src/app/types/Opensearch";
import { twMerge } from "tailwind-merge";

import Link from "../../Typography/Link";

export type AuthorCardProps = {
  author: ProcessedAuthorContent;
  displayWidth: number;
  displayHeight: number;
  imageWrapper?: string;
  hasEmail?: boolean;
  hrefTitle?: boolean;
  authorCardWrapper?: string;
};

export default function AuthorCard({
  author,
  displayWidth,
  displayHeight,
  imageWrapper,
  authorCardWrapper,
  hasEmail = true,
  hrefTitle = false,
}: AuthorCardProps) {
  const { urlPath, email, name } = author;
  return (
    <div className={twMerge("flex gap-4", authorCardWrapper)}>
      <div className={twMerge("w-[110px]", imageWrapper)}>
        <Image
          displayWidth={displayWidth}
          srcWidth={displayWidth}
          srcHeight={displayHeight}
          src={author.authorImage?.url ?? dummyImage}
          alt={name}
          dataTestId="author"
          className="rounded-[55px]"
        />
      </div>
      <div className="flex flex-col justify-center">
        {hrefTitle ? (
          <div className="text-md text-black-200 font-semimedium">{name}</div>
        ) : (
          <a
            href={urlPath}
            className="text-md font-secondary text-blue-300 hover:text-blue-700 hover:underline"
          >
            {name}
          </a>
        )}
        {hasEmail && email && (
          <Link
            className="flex text-xs text-grey-400 gap-1 items-center hover:text-blue-700 hover:underline"
            destination={`mailto:${email}`}
            relativePath={false}
          >
            <img src={mailIcon} width={25} height={17} alt={name} />
            <span>{email}</span>
          </Link>
        )}
      </div>
    </div>
  );
}
