import { replaceDashAndWithSpace } from "@helper/stringTransform";
import { findStyleBySection } from "@src/app/helper/utils";
import { twMerge } from "tailwind-merge";

type SectionTitleProps = {
  sectionName?: string;
  className?: string;
};

export function SectionTitle({ sectionName, className }: SectionTitleProps) {
  const { selectedColor } = findStyleBySection(sectionName) || {};

  return sectionName ? (
    <h1
      className={twMerge(
        "text-lg text-primary font-regular capitalize mb-2",
        `text-${selectedColor}`,
        className,
      )}
    >
      {replaceDashAndWithSpace(sectionName)}
    </h1>
  ) : null;
}
