import { stripeAdsBackground } from "@elements/Advertisement/Advertisement";
import { AdsImu2, AdsImu3 } from "@elements/Advertisement/variant/AdsImu";
import LatestNews from "@elements/LatestNews/LatestNews";
import PodcastSideFrame from "@elements/Podcast/PodcastSideFrame";
import { PageAdTargetValue } from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

export type SideBlockAdsProps = {
  sectionName?: string;
  className?: string;
  adsOnly?: boolean;
  hasImu3?: boolean;
  pageAdTargetingProps: PageAdTargetValue;
};

export default function SideBlockAds({
  sectionName,
  adsOnly = false,
  hasImu3 = true,
  className,
  pageAdTargetingProps,
}: SideBlockAdsProps) {
  return (
    <div
      className={twMerge(
        "mx-auto flex flex-col items-center h-full min-w-[300px]",
        className,
      )}
      style={stripeAdsBackground}
    >
      <AdsImu2
        sectionName={sectionName}
        pageAdTargetType={pageAdTargetingProps}
      />
      {!adsOnly && (
        <>
          <LatestNews />
          <PodcastSideFrame />
        </>
      )}
      {hasImu3 && (
        <AdsImu3
          sectionName={sectionName}
          pageAdTargetType={pageAdTargetingProps}
        />
      )}
    </div>
  );
}
