import { GOOGLE_MAPS_API_KEY } from "@helper/getEnvVariables";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import { useVirtualPVAnalyticsCall } from "@hooks/useAnalyticsCall/useVirtualPVAnalyticsCall";
import useIntersectionObserver from "@src/app/hooks/useIntersectionObserver";
import {
  type MappedMarkers,
  type Point,
  RestaurantDataWithDistance,
} from "@typings/foodMapTypes";
import {
  APIProvider,
  ControlPosition,
  Map,
  MapCameraChangedEvent,
} from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useRef, useState } from "react";

import FoodMapDistanceSlider from "./FoodMapElements/FoodMapDistanceSlider";
import FoodMapInAppBrowserWarning from "./FoodMapElements/FoodMapInAppBrowserWarning";
import FoodMapRestaurantList from "./FoodMapElements/FoodMapRestaurantList";
import FoodMapRestaurantOverlay from "./FoodMapElements/FoodMapRestaurantOverlay";
import Circle from "./GoogleMapComponents/Circle";
import Marker from "./GoogleMapComponents/Marker";
import { locationFromString } from "./helper/helper";
import FetchRestaurants from "./RestaurantFetcher/FetchRestaurants";

export type FoodMapGoogleMapsProps = {
  foodMapWrapper?: string;
  userLocation?: Point;
};

export default function FoodMapGoogleMaps({
  foodMapWrapper,
  userLocation = { lat: 1.295007, lng: 103.853072 },
}: FoodMapGoogleMapsProps) {
  const [markers, setMarkers] = useState<MappedMarkers[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<number>(-1);
  const [restaurantData, setRestaurantData] = useState<
    RestaurantDataWithDistance[]
  >([]);
  const [distance, setDistance] = useState<number>(1000);
  const [radius, setRadius] = useState<number>(1000);
  const [zoom, setZoom] = useState<number | null>();
  const [mapCenter, setMapCenter] = useState<Point>(userLocation);
  const [selectedRestaurant, setSelectedRestaurant] =
    useState<RestaurantDataWithDistance | null>(null);

  const restaurantListRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef<number>(0);

  const bhCustomEventAnalyticsCall = useBHCustomEventAnalyticsCall();
  const virtualPVAnalyticsCall = useVirtualPVAnalyticsCall();

  const fetchData = useCallback(
    (userLocation: Point) => {
      const data = FetchRestaurants(userLocation);
      if (data && data.length > 0) {
        const mappedMarkers: MappedMarkers[] = data.map((response, index) => ({
          id: index + 1,
          position: locationFromString(response.location),
          label: `${index + 1}`,
        }));
        setMarkers(mappedMarkers);
        setRestaurantData(data);
        if (data[0].distance <= 1) {
          bhCustomEventAnalyticsCall(
            "foodmap impression",
            "visible",
            data[0].name,
            true,
          );
        }
      } else {
        console.log("Restaurant Data not available.");
      }
    },
    [bhCustomEventAnalyticsCall],
  );

  useEffect(() => {
    fetchData(userLocation);
  }, [fetchData, userLocation]);

  const handleMarkerClick = useCallback(
    (marker: MappedMarkers) => {
      setSelectedMarker(marker.id);
      const restaurant = restaurantData[marker.id - 1];
      setSelectedRestaurant(restaurant);
      setMapCenter(locationFromString(restaurant.location));
      setZoom(20);
    },
    [restaurantData],
  );

  const handleSliderDistanceChange = useCallback(
    (newDistance: number) => {
      setDistance(newDistance);
      setRadius(newDistance);
      const rad = radius + radius / 2;
      const scale = rad / 350;
      const zoomLevel = 16 - Math.log(scale) / Math.log(2);
      setZoom(zoomLevel);
      bhCustomEventAnalyticsCall("foodmap", "zoom in/out", undefined, false);
    },
    [radius, bhCustomEventAnalyticsCall],
  );

  const selectRestaurant = useCallback(
    (restaurant: RestaurantDataWithDistance) => {
      if (restaurantListRef.current) {
        scrollPositionRef.current = restaurantListRef.current.scrollTop;
      }

      setSelectedRestaurant(restaurant);
      setMapCenter(locationFromString(restaurant.location));
      setZoom(20);
      const id = restaurant.index;
      if (id !== undefined) {
        setSelectedMarker(id);
      }
      virtualPVAnalyticsCall(
        "/foodmap/node/10326",
        `Food Map::${restaurant.name}`,
        restaurant.location,
      );
    },
    [virtualPVAnalyticsCall],
  );

  const closeOverlay = useCallback(
    (e: boolean) => {
      if (e) {
        setSelectedRestaurant(null);
        setMapCenter(userLocation);
        setZoom(15);
        setSelectedMarker(-1);
      }
    },
    [userLocation],
  );

  const handleVisibilityChange = useCallback(
    (element: Element) => {
      const restaurantName = element.getAttribute("data-name") as string;
      bhCustomEventAnalyticsCall(
        "foodmap impression",
        "visible",
        restaurantName,
        true,
      );
    },
    [bhCustomEventAnalyticsCall],
  );

  const { observe, unobserve } = useIntersectionObserver(
    handleVisibilityChange,
    {
      root: restaurantListRef.current,
      rootMargin: "0px",
      threshold: 0.8,
    },
  );

  const handleScroll = useCallback(() => {
    if (restaurantListRef.current) {
      const restaurantElements =
        restaurantListRef.current.querySelectorAll(".restaurant-card");
      restaurantElements.forEach((element) => {
        observe(element);
      });

      return () => {
        restaurantElements.forEach((element) => {
          unobserve(element);
        });
      };
    }
  }, [observe, unobserve]);

  useEffect(() => {
    const refCurrent = restaurantListRef.current;

    const onWheel = () => {
      handleScroll();
    };

    if (refCurrent) {
      refCurrent.addEventListener("wheel", onWheel);
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener("wheel", onWheel);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!selectedRestaurant && restaurantListRef.current) {
      restaurantListRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [selectedRestaurant]);

  const circleColor = "#01519C";

  return (
    <>
      <FoodMapInAppBrowserWarning />
      <div className={foodMapWrapper}>
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
          <Map
            id="foodmap-map-container"
            mapId="foodmap"
            style={{ width: "100vw", height: "40vh" }}
            defaultCenter={userLocation}
            defaultZoom={14}
            zoom={zoom}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
            zoomControl={true}
            zoomControlOptions={{ position: ControlPosition.RIGHT_BOTTOM }}
            center={mapCenter}
            onZoomChanged={(e: MapCameraChangedEvent) => {
              setZoom(e.detail.zoom);
            }}
            onCenterChanged={(e: MapCameraChangedEvent) => {
              setMapCenter(e.detail.center);
            }}
          >
            <Circle
              center={userLocation}
              radius={radius}
              strokeColor={circleColor}
              strokeOpacity={0.9}
              strokeWeight={1}
              fillColor={circleColor}
              fillOpacity={0.2}
            />
            {markers.map((marker, index) => (
              <Marker
                key={index}
                id={marker.id}
                marker={marker}
                onClick={() => {
                  handleMarkerClick(marker);
                }}
                selectedMarker={selectedMarker}
              />
            ))}
          </Map>
        </APIProvider>
      </div>
      {selectedRestaurant ? (
        <FoodMapRestaurantOverlay
          restaurant={selectedRestaurant}
          handleCloseOverlay={closeOverlay}
        />
      ) : (
        <div>
          <FoodMapDistanceSlider
            initialDistance={distance}
            onDistanceChange={handleSliderDistanceChange}
          />
          <div
            className="flex flex-col h-[45vh] overflow-y-scroll pl-lg pr-md pb-xl"
            ref={restaurantListRef}
          >
            <FoodMapRestaurantList
              data={restaurantData}
              filterDistance={distance / 1000}
              onSelectingRestaurant={selectRestaurant}
            />
          </div>
        </div>
      )}
    </>
  );
}
