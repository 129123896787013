import { AdTargetingType, CompanionAdUnitProps } from "@typings/Ads.d";
import { useCallback, useEffect, useMemo, useState } from "react";

import { generateAdvertElementId } from "./helpers";

/**
 * This component renders companinon ads used with infinite scroll.
 * @param rootClassName - The root class name.
 * @param type - The type of ad unit.
 * @param slot - The ad slot.
 * @param id - The id of the ad.
 * @see https://developers.google.com/doubleclick-gpt/reference
 * @returns A IMU ad component. If the script is not ready, it returns error. If the script is ready, it returns the ad.
 */
export function CompanionAdUnit({
  rootClassName = "",
  type,
  slot = "",
  id = generateAdvertElementId(type),
  adSlotSize,
  pageNumber,
  pubAdTargeting,
}: CompanionAdUnitProps) {
  const [error, setError] = useState<string>("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();

  const adTarget: AdTargetingType[] = useMemo(
    () => [
      { key: "pos", value: pageNumber },
      { key: "page_number", value: pageNumber },
    ],
    [pageNumber],
  );

  /**
   * This function is called when the Google script is loaded.
   * It defines the ad slot and loads the ad.
   * @returns void
   * @see https://developers.google.com/doubleclick-gpt/reference#googletag.cmd.push
   */
  const handleDisplayAd = useCallback(() => {
    try {
      // Make sure that googletag.cmd exists.
      window.googletag =
        (window.googletag as typeof googletag | undefined) || {};
      ((window.googletag as typeof googletag).cmd as googletag.CommandArray) =
        ((window.googletag as typeof googletag).cmd as
          | googletag.CommandArray
          | undefined) || [];
      // Correct: Queueing the callback on the command queue.
      (
        (window.googletag as typeof googletag).cmd as googletag.CommandArray
      ).push(function () {
        // Set page-level targeting.
        pubAdTargeting.forEach((target: AdTargetingType) => {
          googletag.pubads().setTargeting(target.key, target.value);
        });

        // Remove any existing ad slots with the same id.
        googletag
          .pubads()
          .getSlots()
          .forEach(function (_slot: googletag.Slot) {
            if (_slot.getSlotElementId()) {
              if (_slot.getSlotElementId() === id) {
                googletag.destroySlots([_slot]);
              }
            }
          });
        // Create the ad slot.
        if (undefined != adSlotSize) {
          const _adSlot = googletag.defineSlot(
            slot,
            adSlotSize as googletag.GeneralSize,
            id,
          ) as googletag.Slot | undefined;

          if (undefined != _adSlot) {
            _adSlot.setCollapseEmptyDiv(true, true);
            _adSlot.addService(googletag.companionAds());

            adTarget.forEach((target: AdTargetingType) => {
              _adSlot.setTargeting(target.key, target.value);
            });

            _adSlot.addService(googletag.pubads());

            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
            googletag.pubads().refresh([_adSlot]);

            // Store the ad slot for later use.
            setAdSlot(_adSlot);
          }
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [slot, id, adSlotSize, pubAdTargeting, adTarget]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (slot && !adSlot) {
        handleDisplayAd();
      }
    }
  }, [slot, handleDisplayAd, adSlot]);

  useEffect(() => {
    /**
     * This function destroys the ad slot when the component is unmounted.
     * @returns void
     * @see https://developers.google.com/doubleclick-gpt/reference#googletag.destroySlots
     */
    return () => {
      const gTag = window.googletag as typeof googletag | undefined;
      if (gTag && gTag.apiReady && adSlot) {
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  return (
    <div className={rootClassName}>
      {!error ? <div data-testid={id} id={id}></div> : error}
    </div>
  );
}
