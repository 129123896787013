import Advertisement from "@components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetValue,
} from "@src/app/types/Ads.d";
import { twMerge } from "tailwind-merge";

import { pubPageAdTargeting } from "../AdsConstants";

type AdsImuProps = {
  sectionName?: string;
  rootClassName?: string;
  pageAdTargetType: PageAdTargetValue;
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @name - name of section or page
 */

export function AdsImu1({
  sectionName,
  rootClassName,
  pageAdTargetType,
}: AdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <Advertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      adUnitProps={{
        type: AdvertisementTypeEnum.IMU1,
        slot: GoogleAdsSlotFactory.imu1(sectionName),
        pubAdTargeting: [PubPageAdTarget],
      }}
    />
  );
}
export function AdsImu2({ sectionName, pageAdTargetType }: AdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <div className="lg:h-[600px]">
      <Advertisement
        rootClassName={twMerge(
          "flex justify-center lg:sticky lg:top-[130px]",
          "!py-0",
        )}
        adUnitProps={{
          type: AdvertisementTypeEnum.IMU2,
          slot: GoogleAdsSlotFactory.imu2(sectionName),
          pubAdTargeting: [PubPageAdTarget],
        }}
      />
    </div>
  );
}

export function AdsImu3({
  sectionName,
  rootClassName,
  pageAdTargetType,
}: AdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <Advertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      adUnitProps={{
        type: AdvertisementTypeEnum.IMU3,
        slot: GoogleAdsSlotFactory.imu3(sectionName),
        pubAdTargeting: [PubPageAdTarget],
      }}
    />
  );
}
