import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function UGCLandingPageMetaData(): PageMetaDataValues {
  const section = "mata-mata";
  const metaTags: MetaTagsProps = {
    title: section,
    description: "Baca dan dapatkan Kempen terkini hanya di BeritaHarian.sg",
    slug: section,
  };

  const gaData: GADataProps = {
    level2: section,
    title: "mata-mata index",
    section: section,
    contentcat: 1,
  };

  return {
    gaData,
    metaTags,
  };
}
