import facebookIcon from "@assets/social/facebook.svg";
import newPostIcon from "@assets/social/newPost.svg";
import telegramIcon from "@assets/social/telegram.svg";
import whatsappIcon from "@assets/social/whatsapp.svg";
import { getWindowOrigin } from "@src/app/helper/utils";

import LinkIcon from "../Icon/LinkIcon";

export type SocialVariant =
  | "facebook"
  | "telegram"
  | "whatsapp"
  | "plainWhatsapp"
  | "newsPost";

export type ShareSocialProps = {
  variant: SocialVariant;
  pageTitle?: string;
  pagePath: string;
  width?: string;
  dateCreated?: string;
};

export default function ShareSocial({
  variant,
  pageTitle,
  pagePath,
  dateCreated,
  width = "35px",
}: ShareSocialProps) {
  const origin = getWindowOrigin();
  const shareTitle = pageTitle && encodeURIComponent(pageTitle);
  const sharePath = encodeURIComponent(`${origin}/${pagePath}`);
  const teleDestination = shareTitle
    ? `${sharePath}&text=${shareTitle}`
    : sharePath;
  const whatsappDestination = shareTitle
    ? `${shareTitle} ${sharePath}`
    : sharePath;
  const newsPostDestination = shareTitle;
  switch (variant) {
    case "facebook":
      return (
        <LinkIcon
          destination={`https://www.facebook.com/sharer/sharer.php?u=${sharePath}`}
          src={facebookIcon}
          alt="whatsapp"
          width={width}
        />
      );
    case "telegram":
      return (
        <LinkIcon
          destination={`https://t.me/share/url?url=${teleDestination}`}
          src={telegramIcon}
          alt="whatsapp"
          width={width}
        />
      );
    case "whatsapp":
      return (
        <div className="flex items-center bg-white-200 rounded-whatsapp w-[98px] h-[35px] gap-1">
          <LinkIcon
            destination={`https://wa.me/?text=${whatsappDestination}`}
            src={whatsappIcon}
            alt="whatsapp"
            width="75px"
            className="p-1"
          />
          <span className="text-xxxs text-grey-800">Kongsi di Whatsapp</span>
        </div>
      );
    case "plainWhatsapp":
      return (
        <LinkIcon
          destination={`https://wa.me/?text=${whatsappDestination}`}
          src={whatsappIcon}
          alt="whatsapp"
          width={width}
        />
      );
    case "newsPost":
      return (
        <div className="flex items-center bg-white-200 rounded-whatsapp w-[160px] h-[35px] gap-1">
          <LinkIcon
            destination={`https://newspost.newslink.sg/?publication=BH&date=${dateCreated}&title=${newsPostDestination}`}
            src={newPostIcon}
            alt="whatsapp"
            width="75px"
            className="p-0.5"
          />
          <span className="text-xxxs text-grey-800">
            Dapatkan artikel ini untuk diterbitkan semula
          </span>
        </div>
      );
    default:
      return null;
  }
}
