import { BrightcoveVideo } from "@src/app/components/BrightcoveVideo/BrightcoveVideo";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { RefObject } from "react";

import ShareSocial, { type SocialVariant } from "../ShareSocial/ShareSocial";

type Props = {
  playing: LatestVideoResponse;
  forwadedRef: RefObject<HTMLDivElement>;
  onEnded?: () => void;
  nextPlaylist?: () => void;
};

const socialIconList = ["facebook", "telegram", "whatsapp"];

export default function VideoPlayer({
  playing,
  forwadedRef,
  onEnded,
  nextPlaylist,
}: Props) {
  const { id, name, topStoryDate } = playing;
  return (
    <>
      <BrightcoveVideo
        videoId={id}
        forwardedRef={forwadedRef}
        onEnded={onEnded}
        nextPlaylist={nextPlaylist}
      />
      <div className="flex flex-col">
        <h3 className="font-secondary font-semimedium text-[28px] leading-xl text-black-300 my-xs">
          {name}
        </h3>
        <div className="flex items-center justify-between">
          <span className="font-secondary text-xxs text-semimedium lg:text-sm text-grey-400">
            {topStoryDate}
          </span>
          <div className="flex gap-2">
            {socialIconList.map((variant, index) => (
              <ShareSocial
                variant={variant as SocialVariant}
                pagePath={id}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
