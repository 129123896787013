import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function PodcastMetaData(section: string): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: section,
    title: `${capitalize(section)}_Index`,
    section: section,
    contentcat: 1,
    contenttype: "listing",
  };

  const metaTags: MetaTagsProps = {
    title: "Beritaharian - Berita Terkini, Setempat, Ekoniaga, Dunia & Sukan",
    description:
      "Berita Harian Singapura - Dengar podcast tentang isu semasa mengenai Singapura, Asia dan dunia.",
    slug: section,
  };

  return {
    gaData,
    metaTags,
  };
}
