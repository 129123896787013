import commentaryImg from "@assets/images/default/Commentary.jpg";
import economyImg from "@assets/images/default/Economy.jpg";
import electionImg from "@assets/images/default/Election.jpg";
import defaultImg from "@assets/images/default/Featured+Stories.jpg";
import foodImg from "@assets/images/default/Food.jpg";
import lifestyleImg from "@assets/images/default/Lifestyle.jpg";
import religionImg from "@assets/images/default/Religion.jpg";
import singaporeImg from "@assets/images/default/Singapore.jpg";
import sportsImg from "@assets/images/default/Sports.jpg";
import worldImg from "@assets/images/default/World.jpg";

export default function getFallbackSectionImage(section: string | undefined) {
  switch (section?.toLowerCase()) {
    case "singapura":
      return singaporeImg;

    case "dunia":
      return worldImg;

    case "ekonomi-kerja":
    case "ekonomi & kerja":
      return economyImg;

    case "sukan":
      return sportsImg;

    case "gaya-hidup":
    case "gaya hidup":
      return lifestyleImg;

    case "hidayah":
      return religionImg;

    case "rencana":
      return foodImg;

    case "wacana":
      return commentaryImg;

    case "ge2020":
      return electionImg;

    default:
      return defaultImg;
  }
}
