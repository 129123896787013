import { UrlMatch } from "@app/data.ts";
import { AuthorPageQuery } from "@caas/AuthorPageQueries";
import fetchBHApi from "@helper/fetchBHApi";
import fetchCass from "@helper/fetchCaas";
import { removeTrailingSlash } from "@helper/removeTrailingLeadingSlash";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import getOSProcessedArticleData, {
  type ProcessedArticleData,
} from "@transformer/useOSResponse";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import type {
  OSResponse,
  PartialCueArticleWithCampaign,
} from "@typings/Opensearch";

export type AuthorPayloadResponseType = {
  data: ProcessedArticleData[];
  authorAlias: string;
};

export const fetchAuthorData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithRedirect<AuthorPayloadResponseType, string>
> => {
  const authorUrlAlias = removeTrailingSlash(urlComponent.path);
  try {
    const data = (await fetchCass({
      searchQuery: AuthorPageQuery(authorUrlAlias),
    })) as OSResponse<PartialCueArticleWithCampaign>;

    const hits = data.payload.hits.total.value;
    if (hits === 0) {
      return { type: ResponseType.CLIENT_ERROR, statusCode: 404 };
    }

    const processedData = getOSProcessedArticleData(data, 200);

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        data: processedData,
        authorAlias: authorUrlAlias,
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchMoreAuthorStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  const response: ProcessedArticleData[] = await fetchBHApi(
    "more-author-stories",
    "POST",
    {
      authorUrlAlias: requestData.filterArticlesBasedOn,
      sort: requestData.sort,
    },
  );

  return response;
}
