import { isClientSide } from "@src/app/helper/utils";
import Cookies from "js-cookie";
import { useEffect } from "react";

export default function Outbrain({ pathName }: { pathName: string }) {
  useEffect(() => {
    if (isClientSide) {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      if (
        typeof window._data != "undefined" &&
        typeof window._data?.articleid != "undefined" &&
        window._data?.articleid != ""
      ) {
        Cookies.set("outbrain_enable", "1");
      } else {
        Cookies.set("outbrain_enable", "0");
      }
    }
  }, []);
  return (
    <div
      className="OUTBRAIN pt-4 mb-3"
      data-src={`https://www.beritaharian.sg/${pathName}`}
      data-widget-id="AR_1"
      data-ob-template="BeritaHarian"
    ></div>
  );
}
