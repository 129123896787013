import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetValue,
} from "@src/app/types/Ads.d";
import cx from "classnames";
import Cookies, { CookieAttributes } from "js-cookie";
import { useCallback, useEffect, useState } from "react";

import { adSlotSizes, adTargetings } from "./AdsConstants";
import styles from "./Advertisement.module.css";
import { generateAdvertElementId, useAdvertLoaded } from "./helpers";

const TYPE = AdvertisementTypeEnum.CATFISH;
const ID = generateAdvertElementId(TYPE);
const CATFISH_VALIDITY = 4;

export type CatfishAdProps = {
  rootClassName?: string;
  slot: string;
  isCatfishEnabled: boolean | undefined;
  pubAdTargeting: AdTargetingType[] | AdTargetingType<PageAdTargetValue>[];
};

/**
 * This component renders an Catfish ad.
 * @param rootClassName - The root class name.
 * @param slot - The ad slot.
 * @see https://developers.google.com/doubleclick-gpt/reference
 * @returns A Catfish ad component. If the script is not ready, it returns error. If the script is ready, it returns the ad.
 */
export default function CatfishAd({
  rootClassName,
  slot,
  isCatfishEnabled,
  pubAdTargeting,
}: CatfishAdProps) {
  const catfishDisplayedCookie = Cookies.get("catfishDisplayed");

  const isAdLoaded = useAdvertLoaded(TYPE, ID);
  const [error, setError] = useState("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();
  const adSlotSize = adSlotSizes[TYPE];
  const adTarget = adTargetings[TYPE];

  const handleDisplayAd = useCallback(() => {
    try {
      googletag.cmd.push(function () {
        // Set page-level targeting.
        pubAdTargeting.forEach((target: AdTargetingType) => {
          googletag.pubads().setTargeting(target.key, target.value);
        });

        googletag
          .pubads()
          .getSlots()
          .forEach(function (_slot) {
            if (_slot.getSlotElementId()) {
              if (_slot.getSlotElementId() === ID) {
                googletag.destroySlots([_slot]);
              }
            }
          });

        const _adSlot = googletag.defineSlot(slot, adSlotSize, ID);

        if (_adSlot) {
          _adSlot.addService(googletag.pubads());

          adTarget?.forEach(({ key, value }) => {
            _adSlot.setTargeting(key, value);
          });

          googletag.pubads().enableSingleRequest();
          googletag.enableServices();

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.googletag.slots["catfish"] = _adSlot;

          // Store the ad slot for later use.
          setAdSlot(_adSlot);
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [slot, adSlotSize, adTarget, pubAdTargeting]);

  useEffect(() => {
    if (typeof window !== "undefined" && isCatfishEnabled != undefined) {
      if (slot && !adSlot) {
        if (
          (typeof catfishDisplayedCookie === "undefined" ||
            (window.dfp_preview_ids != undefined &&
              Array.isArray(window.dfp_preview_ids) &&
              window.dfp_preview_ids.length != 0)) &&
          screen.width < 767 &&
          isCatfishEnabled
        ) {
          handleDisplayAd();
        }
      }
    }
  }, [slot, handleDisplayAd, adSlot, catfishDisplayedCookie, isCatfishEnabled]);

  useEffect(() => {
    return () => {
      const gTag = window.googletag as typeof googletag | undefined;
      if (gTag && gTag.apiReady && adSlot) {
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  useEffect(() => {
    if (isAdLoaded) {
      const expiry = new Date();
      expiry.setTime(expiry.getTime() + CATFISH_VALIDITY * 60 * 60 * 1000);
      const cookieOptions: CookieAttributes = {
        path: "/",
        expires: expiry,
      };

      Cookies.set("catfishDisplayed", "yes", cookieOptions);
    }
  }, [isAdLoaded, catfishDisplayedCookie]);

  return (
    <>
      <div className={cx(rootClassName, !isAdLoaded && styles.invisible)}>
        {!error ? <div id={ID}></div> : error}
      </div>
    </>
  );
}
