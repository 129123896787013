import { ProcessedArticleData } from "@hooks/transformer/useOSResponse";
import { getErrorMessage } from "@src/app/helper/getErrorMessage";
import type {
  FetchMoreArticlesFunction,
  MoreArticlesRequestData,
} from "@typings/MoreArticlesApi";
import React from "react";

import { increasePagination } from "./IncreasePagination";

interface LoadMoreHandlerParams {
  lastLoadedArticle: ProcessedArticleData | undefined;
  loadMoreData: ProcessedArticleData[];
  renderedArticleIds: Set<string>;
  pageSize: number;
  pageNumber: number;
}

export type CreateLoadMoreHandlerProps = {
  requestData: MoreArticlesRequestData;
  fetchFunction: FetchMoreArticlesFunction;
  setHasMoreStories: React.Dispatch<React.SetStateAction<boolean>>;
  setRenderedArticleIds: React.Dispatch<React.SetStateAction<Set<string>>>;
  setLoadMoreData: React.Dispatch<React.SetStateAction<ProcessedArticleData[]>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
} & LoadMoreHandlerParams;

export async function CreateLoadMoreHandler({
  requestData,
  fetchFunction,
  setHasMoreStories,
  setRenderedArticleIds,
  setLoadMoreData,
  setPageNumber,
  lastLoadedArticle,
  loadMoreData,
  renderedArticleIds,
  pageSize,
  pageNumber,
}: CreateLoadMoreHandlerProps): Promise<number> {
  let moreArticlesCount = 0;
  try {
    const lastArticle =
      loadMoreData.length === 0 ? lastLoadedArticle : loadMoreData.at(-1);

    if (lastArticle) {
      const newArticles: ProcessedArticleData[] = [];
      const moreArticles = await fetchFunction(requestData);
      moreArticlesCount = moreArticles.length;
      moreArticles.forEach((article: ProcessedArticleData) => {
        if (!renderedArticleIds.has(article.id)) {
          newArticles.push(article);
          setRenderedArticleIds(
            (prevState) => new Set([...prevState, article.id]),
          );
        }
      });
      setLoadMoreData([...loadMoreData, ...newArticles]);
      if (moreArticlesCount > 0) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
        increasePagination((pageNumber + 1).toString());
      }
      if (newArticles.length < pageSize) {
        setHasMoreStories(false);
      }
    }
  } catch (error) {
    const err = getErrorMessage(error);
    console.error("Something went wrong", err);
  }
  return moreArticlesCount;
}
