import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { removeLeadingSlash } from "@helper/removeTrailingLeadingSlash";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function AuthorMetaData(
  authorAlias: string,
): PageMetaDataValues {
  const metaTags: MetaTagsProps = {
    title: "Author Page - Beritaharian",
    slug: removeLeadingSlash(authorAlias),
  };

  const gaData: GADataProps = {
    level2: "miscellaneous",
    section: "miscellaneous",
    contentcat: 1,
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
