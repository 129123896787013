import { twMerge } from "tailwind-merge";

import Date from "../../Typography/Date";
import PodcastPoster from "./PodcastPoster";
import VideoPoster from "./VideoPoster";

export type ThumbnailProps<T> = T & {
  id?: string;
  description: string;
  name: string;
  published_at: string;
  nowPlaying: boolean;
  thumbnail: string;
  formattedTime?: string | undefined;
  variant: "video" | "podcast";
  handleOnClickThumbnail: (props: T) => void;
};

export default function ThumbnailCard<T>(props: ThumbnailProps<T>) {
  const {
    description,
    name,
    published_at,
    nowPlaying,
    thumbnail,
    formattedTime,
    variant,
    handleOnClickThumbnail,
  } = props;

  const Wrapper = variant === "video" ? "div" : "button";
  const wrapperProps =
    variant === "video"
      ? {}
      : {
          onClick: () => {
            handleOnClickThumbnail(props);
          },
        };

  return (
    <div className="flex py-2 lg:py-4 gap-4">
      <button
        onClick={() => {
          handleOnClickThumbnail(props);
        }}
      >
        {variant === "video" && (
          <VideoPoster src={thumbnail} formattedTime={formattedTime} />
        )}
        {variant === "podcast" && <PodcastPoster src={thumbnail} />}
      </button>

      <Wrapper {...wrapperProps} className="flex flex-col w-full ">
        <div className="flex flex-col-reverse items-end justify-between w-full lg:items-center lg:flex-row">
          <Date
            className="text-grey-400 text-xs self-start lg:items-center"
            dataTestId="date"
            variant="default"
          >
            {published_at}
          </Date>
          {nowPlaying && (
            <span className="text-blue-400 text-xxs" data-testid="nowPlaying">
              Kini dimainkan
            </span>
          )}
        </div>
        <h2
          className={twMerge(
            "text-xs lg:text-md font-secondary text-black-200 leading-xs text-start mb-xs",
            variant === "podcast" && "font-semibold",
          )}
          data-testid="name"
        >
          {name}
        </h2>
        <p
          className="text-xs hidden sm:block self-start text-grey-600 leading-[140%] text-left"
          data-testid="description"
        >
          {description}
        </p>
      </Wrapper>
    </div>
  );
}
