import { CAS_UGC_SECTION_BANNER_ID } from "@helper/getEnvVariables";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import CASBlock from "@src/app/components/CASBlock/CASBlock";
import { type ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import UGCListingPageMetaData from "./UGCListingPageMetaData";

export type UGCEmptyListingProps = {
  sectionName: string;
  data: ProcessedArticleData[];
  urlPath: string;
};

export default function UGCEmptyListing({
  sectionName,
  data,
  urlPath,
}: UGCEmptyListingProps) {
  const campaignTitle = data[0].campaignTitle
    ? data[0].campaignTitle
    : data[0].title;

  const pageUrl = `${sectionName}/${urlPath}`;
  const { gaData, metaTags } = UGCListingPageMetaData({
    sectionName,
    pageUrl,
    campaignTitle,
  });

  const basePageProps: BHBasePageProps = {
    sectionUniqueName: sectionName,
    hasLb1Ads: false,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: PageAdTargetingTypeEnum.LISTING,
  };

  return (
    <BHBasePage {...basePageProps}>
      <CASBlock casId={CAS_UGC_SECTION_BANNER_ID} />
      <div className="text-lg font-primary">
        <div className="mb-sm font-semibold">
          <a
            className="text-blue-300 hover:text-blue-700 hover:underline"
            href={`/${sectionName}`}
          >
            {sectionName}
          </a>
        </div>
        <div className="flex flex-col-reverse md:flex-row md:items-center justify-between lg:justify-start mb-xs">
          <div className="self-start w-12/12 lg:w-6/12 xl:w-7/12 text-blue-100 font-semibold">
            {campaignTitle}
          </div>
        </div>
      </div>
    </BHBasePage>
  );
}
